<template>
  <loader v-if="this.LoadingTimingFlag==false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2" bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
  <div class="col-lg-12">
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="frist">
        <div class="filter-area" style="padding-left: 40px;">
          <form @submit.prevent="search">
            <div class="filter-main">
              <div class="right">
                <div class="item">
                  <div class="right-align NameLabelBase">
                   <label class="LabelName">Name:</label>
                    <input class="single-input-advance" placeholder="Name" type="text" v-model="nick_name" />
                  </div>
                </div>
                <div v-if="!isLoggedIn" class="item">
                  <select v-model="gender" class="outer_select_gender">
                    <option value="" disabled>Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="item">
                  <span class="show AgeShow">Age: </span>
                  <select v-model="min_age" class="AgeDropdown outer_select_age">
                    <option value="" disabled>Min</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                    <option value="32">32</option>
                    <option value="33">33</option>
                    <option value="34">34</option>
                    <option value="35">35</option>
                    <option value="36">36</option>
                    <option value="37">37</option>
                    <option value="38">38</option>
                    <option value="39">39</option>
                    <option value="40">40</option>
                    <option value="41">41</option>
                    <option value="42">42</option>
                    <option value="43">43</option>
                    <option value="44">44</option>
                    <option value="45">45</option>
                    <option value="46">46</option>
                    <option value="47">47</option>
                    <option value="48">48</option>
                    <option value="49">49</option>
                    <option value="50">50</option>
                    <option value="51">51</option>
                    <option value="52">52</option>
                    <option value="53">53</option>
                    <option value="54">54</option>
                    <option value="55">55</option>
                    <option value="56">56</option>
                    <option value="57">57</option>
                    <option value="58">58</option>
                    <option value="59">59</option>
                    <option value="60">60</option>
                    <option value="61">61</option>
                    <option value="62">62</option>
                    <option value="63">63</option>
                    <option value="64">64</option>
                    <option value="65">65</option>
                    <option value="66">66</option>
                    <option value="67">67</option>
                    <option value="68">68</option>
                    <option value="69">69</option>
                    <option value="70">70</option>
                    <option value="71">71</option>
                    <option value="72">72</option>
                    <option value="73">73</option>
                    <option value="74">74</option>
                    <option value="75">75</option>
                    <option value="76">76</option>
                    <option value="77">77</option>
                    <option value="78">78</option>
                    <option value="79">79</option>
                    <option value="80">80</option>
                    <option value="81">81</option>
                    <option value="82">82</option>
                    <option value="83">83</option>
                    <option value="84">84</option>
                    <option value="85">85</option>
                    <option value="86">86</option>
                    <option value="87">87</option>
                    <option value="88">88</option>
                    <option value="89">89</option>
                    <option value="90">90</option>
                    <option value="91">91</option>
                    <option value="92">92</option>
                    <option value="93">93</option>
                    <option value="94">94</option>
                    <option value="95">95</option>
                    <option value="96">96</option>
                    <option value="97">97</option>
                    <option value="98">98</option>
                    <option value="99">99</option>
                  </select>
                  <div class="separator ">-</div>
                  <select v-model="max_age" class="AgeDropdownTo outer_select_age">
                    <option value="" disabled>Max</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                    <option value="32">32</option>
                    <option value="33">33</option>
                    <option value="34">34</option>
                    <option value="35">35</option>
                    <option value="36">36</option>
                    <option value="37">37</option>
                    <option value="38">38</option>
                    <option value="39">39</option>
                    <option value="40">40</option>
                    <option value="41">41</option>
                    <option value="42">42</option>
                    <option value="43">43</option>
                    <option value="44">44</option>
                    <option value="45">45</option>
                    <option value="46">46</option>
                    <option value="47">47</option>
                    <option value="48">48</option>
                    <option value="49">49</option>
                    <option value="50">50</option>
                    <option value="51">51</option>
                    <option value="52">52</option>
                    <option value="53">53</option>
                    <option value="54">54</option>
                    <option value="55">55</option>
                    <option value="56">56</option>
                    <option value="57">57</option>
                    <option value="58">58</option>
                    <option value="59">59</option>
                    <option value="60">60</option>
                    <option value="61">61</option>
                    <option value="62">62</option>
                    <option value="63">63</option>
                    <option value="64">64</option>
                    <option value="65">65</option>
                    <option value="66">66</option>
                    <option value="67">67</option>
                    <option value="68">68</option>
                    <option value="69">69</option>
                    <option value="70">70</option>
                    <option value="71">71</option>
                    <option value="72">72</option>
                    <option value="73">73</option>
                    <option value="74">74</option>
                    <option value="75">75</option>
                    <option value="76">76</option>
                    <option value="77">77</option>
                    <option value="78">78</option>
                    <option value="79">79</option>
                    <option value="80">80</option>
                    <option value="81">81</option>
                    <option value="82">82</option>
                    <option value="83">83</option>
                    <option value="84">84</option>
                    <option value="85">85</option>
                    <option value="86">86</option>
                    <option value="87">87</option>
                    <option value="88">88</option>
                    <option value="89">89</option>
                    <option value="90">90</option>
                    <option value="91">91</option>
                    <option value="92">92</option>
                    <option value="93">93</option>
                    <option value="94">94</option>
                    <option value="95">95</option>
                    <option value="96">96</option>
                    <option value="97">97</option>
                    <option value="98">98</option>
                    <option value="99">99</option>
                  </select>
                </div>
                <div class="item CountryDropdown_item">
                  <span v-if="isLoggedIn" class="show">Country:</span>
                  <select style="width: 370px" id="country" v-model="country" @change="getstates($event)" class="CountryDropdown outer_select_CountryDropdown">
                    <option value=""  selected>Select Country</option>
                    <option value="223">USA</option>
                    <option value="36">Canada</option>
                  </select>
                </div>

               <div class="item">
                  <!-- <span class="show stateShow"></span> -->
                  <select style="display: none;" id = "state" v-model="state" class="StateDropdown outer_select_state" >
                    {
                     <option value="" disabled selected v-if="this.statesBoolValue===true">Select State</option>
                     <option value="" disabled selected v-else>Select State</option>
                    }
                    <option id="Selectoption" v-if="this.statesBoolValue===false">
                       Select Country First
                    </option>
                    <option id="Selectoption" v-for="state in states" :key="state" :value="state.id">
                      {{ state.name }}
                    </option>
                  </select>
                </div>
                <!-- <div class="item">
                  <span class="show">Photo:</span>
                  <div class="right-align-2">
                    <input type="checkbox" v-model="photo" id="photo" value="photo" />
                  </div>
                </div> -->

                <div class="item">
                  <div class="joun-button searchButton">
                    <button type="submit" class="custom-button searchButton2">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <div class="tab-pane fade show" id="second"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicSearchBar",

  data() {
    return {
      nick_name: "",
      gender: "",
      min_age: "",
      max_age: "",
      country: "",
      state: "",
      photo: "",
      state: "",
      states: [],
      page:1 ,
      statesBoolValue: false,
      LoadingTimingFlag:true,

    };
  },

  methods: {
    async search() {
     console.log(this.gender,"gender");
      this.$router.push({
        path: "search-results",
        query: {
          search: "basic-search",
          nick_name: this.nick_name,
          gender: this.gender,
          min_age: this.min_age,
          max_age: this.max_age,
          country: this.country,
          state: this.state,
          page: this.page,

        },
      });
    },

    async getstates(event)
    {
      this.LoadingTimingFlag = false;
      if(event.target.value == ''){
      $("#state").css("display", "none");
      $("#country").css("width", "340px");
      }else{
      $("#state").css("display", "block");
      if(localStorage.getItem("token")){
        $("#country").css("width", "65%");
      }else{
      $("#country").css("width", "100%");
      }
      }
      await this.axios
        .get("get_states", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.states.splice(0);
          result.data.data.states.forEach(element => {
          // console.log(result.data.data.states);
          if(element.country_id == event.target.value)
          {
            this.states.push(element);
            this.statesBoolValue = true;
            // console.log(this.statesBoolValue,"statesBoolValue")
          }
          else if (event.target.value==''){
            this.statesBoolValue = false;
            // console.log(this.statesBoolValue,"statesBoolValue")
          }
          this.LoadingTimingFlag = true;
          });
        })
        .catch((err) => { });
    },
  },
  computed: {
    isLoggedIn() {
      return (this.isLoggedIn = localStorage.getItem("token"));
    },
  },


  // mounted() {
  //   console.log(window.innerWidth,"ScreenResolution");
  // },
};
</script>

<style scoped>
  /* select {
    width: auto !important;
  } */
  .AgeDropdown{
    width: auto;
  }
  .StateDropdown{
    width: 235px;
  }
  .searchButton{
    margin-left: 7px;
  }
  .LabelName{
    display: none;
  }
  @media (max-width: 800px){
  .custom-button{
    width: 100%;
  }
}

  @media (max-width: 1920px){
    .AgeDropdownTo{
      width: auto;
    }
    .CountryDropdown{
      width: auto;
    }
    .LabelName{
      display: none;
    }
  }
/* @media (max-width: 800px) and (min-width: 545px){
.StateDropdown{
  width: 350px;
  margin-left: 43px;
}
option{
  width: auto;
  overflow-x: auto;
}
.CountryDropdown{
  width: 350px;
  margin-left: 10px;
}
.AgeDropdown{
   margin-left: 37px;
}
.single-input-advance{
  margin-left: 30px;
  width: 350px;
}
.searchButton{
  margin-left: 180px;
  margin-top: 10px;
}
.LabelName{
  display: block;
  margin-top: 10px;
}
.NameLabelBase{
  display: flex;
}
.item{
  margin-bottom: 10px;
}


} */
@media (max-width: 1199px) and (min-width: 993px){
.StateDropdown{
  width: 165px;
  margin-left: 0px;
  /* font-size: 13px; */
  overflow: auto;
}
.StateDropdown option{
  width: 165;


}
.CountryDropdown{
  width: 120px;
  margin-left: 0px;
}
.AgeDropdown{
   margin-left: 0px;
}
.single-input-advance{
 width: 90%;
}
.searchButton{
  margin-left: 400px;
  margin-top: 5px;
}
.AgeShow{
  margin-left: -17px;
}



}
@media (max-width: 990px) and (min-width: 801px){
  .LabelName{
  display: block;
  margin-top: 10px;
}
.NameLabelBase{
  display: flex;
}
.StateDropdown{
  width: 250px;
  margin-left: 0px;
  /* font-size: 13px; */
  overflow: auto;
}
.StateDropdown option{
  width: 165;
}
.CountryDropdown{
  width: 206px;
  margin-left: 0px;
}
.single-input-advance{
 width: 100%;
 margin-left: 24px;
}
.searchButton{
  margin-left: 300px;
  margin-top: 5px;
}
.AgeShow{
  margin-left: 10px;
}
.stateShow{
   margin-left: 10px;
}
.item{
  margin-bottom: 10px;
  width: 48%;
}
.AgeDropdown{
  margin-left: 7px;
  width: 35%;
}
.AgeDropdownTo{
  width: 35%;
}



}
@media (max-width: 1200px) and (min-width: 1350px){
.StateDropdown{
  width: 165px;
  margin-left: 0px;
  /* font-size: 13px; */
  overflow: auto;
}
.StateDropdown option{
  width: 165;


}
.CountryDropdown{
  width: 120px;
  margin-left: 0px;
}
.AgeDropdown{
   margin-left: 0px;
}
.single-input-advance{
 width: 90%;
}
.searchButton{
  margin-left: 400px;
  margin-top: 5px;
}
.AgeShow{
  margin-left: -17px;
}



}
@media (max-width: 800px){
  .item{
    width: 100%;
  }
.StateDropdown{
  width: 100%;
  margin-left: 0px;

}
.CountryDropdown{
  width: 100%;
  margin-left: 0px;
}
.AgeDropdown{
   margin-left: 0px;
    width: 48%;
}
.AgeDropdownTo{
  width: 48%;
}
.single-input-advance{
  margin-left: 0px;
}
.searchButton{
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.LabelName{
  display: block;
  margin-top: 10px;

}
.NameLabelBase{
  display: block;
  width: 100%;
}
.item{
  margin-bottom: 10px;
}
.AgeShow{
  width: 100%;
}



}

</style>
