<template>
    <div class="col-xl-4 col-lg-4 col-md-6">
        <div class="left-profile-area box-shadow">
            <div class="profile-uplodate-photo">
                <div class="row">
                    <div class="col-6">
                        <h6 class="p-u-p-header">
                            <i class="fas fa-camera"></i> Your Pictures
                        </h6>
                    </div>
                    <div class="col-6">
                        <h6 class="p-u-p-header">
                            <span class="header-span">
                                <router-link to="gallery">View All</router-link>
                            </span>
                            <!-- <span class="header-span">|</span>
                                        <span class="header-span"><router-link to="">Upload</router-link></span> -->
                        </h6>
                    </div>
                </div>
                <div class="p-u-p-list">
                    <div class="my-col" v-for="img in gallery_images" :key="img">
                        <div class="img">
                            <img :src="img" alt="" style="height: 100%;object-fit: contain;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DashboardGallery",

    data() {
        return {
            gallery_images: '',
        }
    },

    methods: {
        async getImages() {
            await this.axios
                .get('get_dashboard_gallery/' + JSON.parse(localStorage.getItem("user"))[0].member_id, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })

                .then((result) => {
                    result.data.data.gallery.splice(2);
                    // console.log(result.data.data);
                    this.gallery_images = result.data.data.gallery;
                })
                .catch((err) => { });
        },
    },

    mounted() {
        window.scrollTo(0,0)
        this.getImages();
    },
}


</script>

<style scoped>
@media (max-width: 1900px) and (min-width: 992px) {
.profile-section .left-profile-area .profile-uplodate-photo {
    height: 270px;
    margin-top: 0px;
}
}

h6 {
    margin-top: 0px !important;
}

.header-span {

    font-size: 14px;
    padding: 0 2px;
    font-weight: normal;
    margin-bottom: 25px;
}

.profile-section .left-profile-area .profile-uplodate-photo {

    margin-top: 0px;
}
.profile-section .left-profile-area .profile-uplodate-photo .p-u-p-list .my-col .img img{
width: 100%;
height: auto;
}

.profile-section .left-profile-area .profile-uplodate-photo .p-u-p-list .my-col .img {
    position: relative;
    margin-bottom: 20px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: contain !important;
    width: 125px;
    height: 125px;

box-shadow: rgb(176 171 193 / 40%) 0px 5px 30px 0px;
}

.box-shadow {
    box-shadow: rgb(176 171 193 / 40%) 0px 5px 30px 0px;
}
.profile-section .left-profile-area .profile-uplodate-photo .p-u-p-header {
    font-size: 16px;
    font-weight: 600;
    display: block;
    padding: 0px 10px;
    margin-bottom: 25px;
    float: right;
}
</style>
