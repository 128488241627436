<template>
  <div>
    <!-- ==========Banner-Section========== -->
    <section class="banner-section">
      <div class="container">
        <div class="row">
          <div class="searchbaar">
            <BasicSearchBar></BasicSearchBar>
          </div>
        </div>
      </div>
    </section>
    <!-- ==========Banner-Section========== -->
    <!-- <FeaturedMembers></FeaturedMembers> -->
    <!-- ==========Community-Sectionn========== -->
    <!-- ==========Feature-Section========== -->
    <section class="feature-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="single-feature wow fadeInUp" data-wow-delay="0.1s">
              <div class="icon">
                <img src="assets/images/feature/icon01.png" alt="" />
              </div>
              <h4>100% Verified</h4>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-feature wow fadeInUp" data-wow-delay="0.2s">
              <div class="icon">
                <img src="assets/images/feature/icon02.png" alt="" />
              </div>
              <h4>Most Secure</h4>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-feature wow fadeInUp" data-wow-delay="0.3s">
              <div class="icon">
                <img src="assets/images/feature/icon03.png" alt="" />
              </div>
              <h4>100% Privacy</h4>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-feature wow fadeInUp" data-wow-delay="0.4s">
              <div class="icon">
                <img src="assets/images/feature/icon04.png" alt="" />
              </div>
              <h4>Smart Matching</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==========Feature-Section========== -->
    <!-- ==========Start-Flirting-Section========== -->
    <section class="flirting-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="content" style="text-align: center;">
              <div class="section-header">
                <h6 class="sub-title extra-padding wow fadeInUp">
                  Meet New People Today!
                </h6>
                <h2 class="title extra-padding wow fadeInUp">Start Searching</h2>
                <p>
                  In our modern day and age dating apps have become an integral part of
                  our lives. They allow you to check the profile of singles living near
                  you, to chat with them, to meet them and maybe to fall in love.
                </p>
                <br />
                <p>
                  If you’re searching for a simple dating app with free features allowing
                  to meet singles, you’re in good hands with Pairko. With Pairko you get
                  all you need from a mobile dating app, which presents you thousands of
                  users through your smartphone in a very pleasant experience.
                </p>
                <router-link to="/login" class="custom-button" style="ma">Seek Your Partner</router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="img">
              <!-- <img class="bg-shape" src="assets/images/flirting/circle.png" alt=""> -->
              <img src="assets/isna-images/about-home.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==========Start-Flirting-Section========== -->
    <!-- ==========How it work Section========== -->
    <section class="how-it-work-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="content">
              <div class="section-header">
                <h2 style="color: #ffffff" class="title wow fadeInUp">
                  How Does It Work?
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="single-work-box wow fadeInUp" data-wow-delay="0.1s">
              <div class="icon">
                <img src="assets/isna-images/create-an-account.png" alt="" />
                <div class="number">01</div>
              </div>
              <h4 class="title">Create an account</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-work-box wow fadeInUp" data-wow-delay="0.2s">
              <div class="icon">
                <img src="assets/isna-images/become-a-member.png" alt="" />
                <div class="number">02</div>
              </div>
              <h4 class="title">Become a member</h4>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-work-box wow fadeInUp" data-wow-delay="0.3s">
              <div class="icon">
                <img src="assets/isna-images/start-searching.png" alt="" />
                <div class="number">03</div>
              </div>
              <h4 class="title">Start Searching</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==========How it work Section========== -->
    <!-- ==========Latest-Registered-Section========== -->
    <RecentMemberSlider></RecentMemberSlider>
    <!-- ==========Latest-Registered-Section========== -->
    <section class="join-now-section">
      <!-- <img class="shape1" src="assets/images/join/heartshape.png" alt=""><img class="shape2" src="assets/images/join/img.png" alt=""> -->
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="content">
              <div class="section-header white-color">
                <h2
                  class="title wow fadeInUp"
                  style="visibility: visible; animation-name: fadeInUp; margin-top: -7rem;"
                >
                  Become a Member & Find your Soulmate
                </h2>
              </div>
              <router-link to="/register" class="custom-button">Join Now</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==========Statistics-Section========== -->
    <section class="sucess-stories-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="content">
              <div class="section-header">
                <h2
                  class="title wow fadeInUp"
                  style="visibility: visible; animation-name: fadeInUp"
                >
                  Articles
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6" v-for="article in articles" :key="article">
            <div
              class="single-story-box wow fadeInUp"
              data-wow-delay="0.1s"
              style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;"
            >
            <router-link :to="{
                                                path: '/single-article',
                                                query: {
                                                id: `${article.content_id}`,
                                                },
                                            }">
              <!-- <div class="img">
                <img src="assets/images/sucess/img1.jpg" alt="" />
              </div> -->
              <div class="content">
                <p class="date" style = "color: black">{{formatDate(article.created_on)}}</p>
                <h4 class="title"  style = "color: black">{{article.content_name}}</h4>
                <span  style = "color: black" class="limit" v-html="article.description.substring(0,195)+'..' "  ></span>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==========Statistics-Section========== -->
  </div>
</template>
<script>
// @ is an alias to /src
//import HomeView from '@/components/HomePage.vue'
import RecentMemberSlider from "@/components/RecentMemberSlider.vue";
import FeaturedMembers from "@/components/FeaturedMembers.vue";
import BasicSearchBar from "@/components/BasicSearchBar.vue";
import dayjs from "dayjs";
import PublicHeader from "@/components/PublicHeader.vue";


export default {
  name: "HomeView",
  data() {
    return {
      nick_name: "",
      min_age: "",
      max_age: "",
      country: "",
      state: "",
      photo: "",
      articles: '',
    };
  },
  components: {
    RecentMemberSlider,
    FeaturedMembers,
    BasicSearchBar,
    PublicHeader
},

  methods: {

    async getArticles() {
      await this.axios
        .get("get_dashboard_articles", {
        })
        .then((result) => {
          console.log(result.data.data.articles);
          this.articles = result.data.data.articles;
        })
        .catch((err) => {});
    },

    formatDate(dateString) {
      const date = dayjs(dateString);
          // Then specify how you want your dates to be formatted
      return date.format('dddd MMMM D, YYYY');
    }
  },
  computed: {
    isLoggedIn() {
      return (this.isLoggedIn = localStorage.getItem("token"));
    },
   gotoTop() {
    window.scrollTo(0,0)
      },
    },

  mounted() {
    this.getArticles();
    if(this.isLoggedIn){
      this.$router.push("dashboard");
    }
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
  },
};
</script>

<style scoped>
.title {
  margin-right: 10px;
}
@media (min-width: 1200px) and (max-width: 1920px) {
  .searchbaar{
  margin-top: -220px;
}
}


</style>
