<template>
  <loader v-if="this.LoadingTimingFlag == false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2"
    bg="#333333" objectbg="#333333" opacity="50" name="spinning">
  </loader>
  <div id="mycontainer">
    <aside>
      <!-- <header>
        <input type="text" placeholder="search" />
      </header> -->
      <button class="custom-button" @click="gotodash()" style=" margin: 25px 0px 10px 18px;">
        Go Back
      </button>
      <ul v-if="chats && chats != ''">
        <li v-for="chat in chats" :key="chat">
          <a :href="$router.resolve({
            path: '/chat',
            query: {
              member_id: `${chat.members.member_id}`,
            },
          }).href
            ">
            <span v-if="chat.member_image != ''">
              <img class="chat-avatar" :src="`${chat.member_image}`" alt="" />
            </span>
            <span v-else-if="chat.members.gender == 'Female'">
              <img class="chat-avatar" src="" alt="" />
            </span>
            <span v-else>
              <img class="chat-avatar" src="/img/dummyImage.eda79a3a.png" alt="" />
            </span>
            {{ chat.members.first_name }} {{ chat.members.last_name }}
            <span class="unread-counter"  v-show="chat.unreadMessages > 0" :id="`unread-counter-${chat.members.member_id}`">
              <span class="unread-messages" :id="`unread-messages-${chat.members.member_id}`">
                {{ chat.unreadMessages }}
              </span>
            </span>
            <!-- <div>
              <span v-if="chat.sender_id == auth_user">
              <h3>
                <span class="status orange"></span>
                offline
              </h3>
              </span>
            </div> -->
          </a>
        </li>
      </ul>
      <ul v-else class="empty_chat">
        <li>
          <a>

            <div style="text-align:center;">
              <!-- <span v-if="chat.sender_id == auth_user"> -->
              <!-- <div v-if="this.chats.length<2">
                  <h2 id="no_chat">No Chats Available Yet!</h2>
                </div> -->
              <h2 id="no_chat">No Chats Available Yet!</h2>

              <!-- </span> -->
            </div>
          </a>
        </li>
      </ul>
    </aside>
    <main>
      <header v-if="receiver_image != ''">
        <img class="chat-avatar" :src="`${receiver_image}`" alt="" />
        <div>
          <h2>{{ receiver.first_name }} {{ receiver.last_name }}</h2>
          <!-- <h3>already 1902 messages</h3> -->
        </div>
      </header>
      <ul id="chat" ref="hasScrolledToBottom">
        <span v-for="msg in messages" :key="msg">
          <li class="me" v-if="msg.sender_id == auth_user">
            <div class="entete">
              <h3>{{ formatDate(msg.created_at) }}</h3>
            </div>
            <div class="message">
              {{ msg.message }}
            </div>
          </li>
          <li class="you" v-else>
            <div class="entete">
              <h3>{{ formatDate(msg.created_at) }}</h3>
            </div>
            <div class="message">
              {{ msg.message }}
            </div>
          </li>
        </span>
      </ul>
      <footer v-if="this.check != ''" class="row">
        <textarea id="btn-input" type="text" name="message" class="col-sm-10 form-control input-sm message-"
          placeholder="Type your message here..." v-model="newMessage" @keyup.enter="addMessage"></textarea>
        <input type="hidden" v-model="receiver.member_id" />
        <a class="col-sm-2" id="btn-chat" @click="addMessage">Send</a>
      </footer>
    </main>
  </div>
</template>

<script>

//window.Pusher = require('pusher-js');
import { ref, reactive, onMounted, onUpdated } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import Echo from "laravel-echo";

export default {
  name: "ChatView",
  props: ["user"],

  data() {
    return {
      receiver: '',
      receiver_image: '',
      check: '',
      showMessage: false,
      auth_user: JSON.parse(localStorage.getItem('user'))[0].member_id,
      LoadingTimingFlag: false,
      unreadMessagesCount: 0,
      messages: []
    };
  },

  setup(props) {
    const chats = ref([]); // Initialize chats as a reactive array
    // const messages = ref([]);
    const messages = ref([])
    // const auth_id = window.auth_id;
    const newMessage = ref('')
    const hasScrolledToBottom = ref('')
    const auth_user = ref(JSON.parse(localStorage.getItem('user'))[0].member_id)
    const route = useRoute()
    onMounted(() => {
      fetchMessages();
      fetchReadMessages();
    })


    onUpdated(() => {
      scrollBottom()
    })

    window.Pusher = require("pusher-js");

    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_WEBSOCKETS_KEY,
      host: window.location.hostname + ':6001',
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            //HERE header needed
            const options = {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
              }
            };
            const data = {
              socket_id: socketId,
              channel_name: channel.name,
            };
            axios
              .post(`/broadcasting/auth`, data, options)
              .then((response) => {
                callback(false, response.data);
              })
              .catch((error) => {
                callback(true, error);
              });
          },
        };
      },
    });

    const fetchReadMessages = async (member_id) => {
      axios
        .get('/messages-read/' + member_id, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        .then((res) => {
          console.log(res)
          // for (let i = 0; i < len; i++) {
          //   messages.value.push({
          //     message: res.data.data.messages[i].message,
          //     created_at: res.data.data.messages[i].created_at,
          //     sender_id: res.data.data.messages[i].sender_id,
          //     reciver_id: res.data.data.messages[i].reciver_id,
          //   });
          // }
        })
    }
    // Pusher.logToConsole = true;
    // window.Echo = new Echo({
    //   broadcaster: "pusher",
    //   key: process.env.VUE_APP_WEBSOCKETS_KEY,
    //   encrypted: false,
    //   host: window.location.hostname + ':6001',
    //   authEndpoint: "http://localhost:8000/api/broadcasting/auth",
    //   auth: {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Accept" : "application/json",
    //       "Authorization": `Bearer ${localStorage.getItem("token")}`,
    //     }
    //   },

    //   // forceTLS: true,

    //   disableStats: true,
    // });

    //   window.Echo = new Echo({
    //     broadcaster: "pusher",
    //     cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    //     encrypted: true,
    //     key: process.env.VUE_APP_WEBSOCKETS_KEY,
    //     authorizer: (channel, options) => {
    //         return {
    //             authorize: (socketId, callback) => {
    //                 axios.post('/broadcasting/auth', {
    //                     socket_id: socketId,
    //                     channel_name: channel.name
    //                 })
    //                 .then(response => {
    //                     callback(false, response.data);
    //                     console.log('response', 'sdsad');
    //                 })
    //                 .catch(error => {
    //                     callback(true, error);
    //                 });
    //             }
    //         };
    //     },
    // })

    window.Echo.private(`chat-channel.${route.query.member_id}`).listen('SendMessage', e => {
      // console.log(route.query.member_id , auth_user.value, e.alldata.message.receiver_id + 'Event listen CommentS ent')
      console.log(e.alldata.message.sender_id)
      if (auth_user.value == e.alldata.message.receiver_id) {
        messages.value.push({
          message: e.alldata.message.message,
          user: e.user.id
        })
        fetchReadMessages(e.alldata.message.sender_id);
      }
      //  else {
      //   const unreadMessages = e.alldata.unreadMessages;
      //   console.log(this.chats)
      // }
    }
    )
    // window.Echo.channel(`chat-channel`).listen(
    //   "SendMessage",
    //   (e) => {
    //     console.log('asdasdasdadad');
    //     messages.value.push({
    //       message: e.message.message,
    //     });
    //   }
    // );

    const fetchMessages = async () => {
      axios
        .get("/messages/" + route.query.member_id, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          let len = res.data.data.messages.length;
          console.log(res.data.data.messages);
          for (let i = 0; i < len; i++) {
            messages.value.push({
              message: res.data.data.messages[i].message,
              created_at: res.data.data.messages[i].created_at,
              sender_id: res.data.data.messages[i].sender_id,
              reciver_id: res.data.data.messages[i].reciver_id,
            });
          }
        });
    };

    window.Echo.channel('notification') // Public channel
      .listen('SendMessage', (data) => {
        const senderId = data.alldata.message.sender_id
        const unreadMessages = data.alldata.unreadMessages
        console.log(route.query.member_id , senderId, auth_user.value);

        if (route.query.member_id != senderId && senderId != auth_user.value) {
          document.getElementById('unread-messages-' + senderId).innerText = unreadMessages
          document.getElementById('unread-counter-' + senderId).style.display = 'inline-flex';
        }
      })
    const addMessage = async () => {
      let user_message = {
        message: newMessage.value,
        sender_id: auth_user.value,
        receiver: route.query.member_id,
      };

      // Add the new message locally
      messages.value.push(user_message);

      try {
        const response = await axios.post("/messages", user_message, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        // console.log(response);



        // console.log(response.data);
      } catch (error) {
        console.error("Error sending message:", error);
      }

      // Clear the input field
      newMessage.value = "";
    };


    const scrollBottom = () => {
      if (messages.value.length > 1) {
        let el = hasScrolledToBottom.value;
        el.scrollTop = el.scrollHeight;
      }
    };

    return {
      messages,
      newMessage,
      addMessage,
      fetchMessages,
      fetchReadMessages,
      hasScrolledToBottom
    };
  },

  methods: {

    handleNewMessage(data) {
    const senderId = data.alldata.message.sender_id;
    const unreadMessages = data.alldata.unreadMessages;

    // Find the chat by senderId and update its unreadMessages count
    const chat = this.chats.find(chat => chat.members.member_id === senderId);

    if (chat && this.auth_user.value !== senderId && route.query.member_id !== senderId) {
      chat.unreadMessages = unreadMessages;
    }
  },
    async fetchRecipientDetails() {
      axios
        .get("/get_member_profile/" + this.$route.query.member_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res.data.data, 'receiver');
          this.receiver = res.data.data.member_profile[0];
          this.receiver_image = res.data.data.member_image;
          if (JSON.parse(localStorage.getItem("user"))[0].gender == this.receiver.gender) {
            alert("Not allow to chat with same gender.");
            this.$router.push("dashboard");
          }

        });
    },
    toggleMessage() {
      this.showHeader = !this.showHeader;
    },
    async fetchChats() {
      axios
        .get("/chats", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          this.chats = res.data.data.chats;
          console.log("Chat =>", this.chats);
          this.LoadingTimingFlag = true;
        });
    },
    gotodash(){
      // this.$router.push("dashboard");
      this.$router.go(-1); // Navigate to the previous route
    },
    formatDate(dateString) {

       const parsedDate = dayjs(dateString, "DDMM HH:mm:ss");
      return parsedDate.format("DD MMMM YYYY HH:mm:ss");
    },
  },

  mounted() {
    if (JSON.parse(localStorage.getItem("user"))[0].member_id == this.$route.query.member_id) {
      this.$router.push("dashboard");
    }
    this.check = this.$route.query.member_id;
    this.fetchChats();
    this.fetchRecipientDetails();
  },
  created() {
    this.timer = setInterval(this.fetchEventsList, 300000);
  },
};
</script>

<style scoped>
.chat-avatar {

  height: 50px;
  width: 50px !important;
  object-fit: cover;
  object-position: top;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: flex;
}

#mycontainer main footer textarea {
  resize: none;
  border: none;
  display: block;
  width: 100%;
  height: 56px;
  border-radius: 3px;
  padding: 10px;
  font-size: 13px;
  margin-bottom: 13px;
}

#mycontainer #chat {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
  height: auto;
  max-height: 300px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

#btn-chat {
  padding: 14px;
  background: #6fbced;
  height: 56px;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}

#no_chat {
  color: red;
  display: none;
}

.unread-counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff4757;
  color: white;
  font-size: 7px;
  font-weight: bold;
  border-radius: 50%;
  /* margin-top: -3px; */
  padding: 4px;
  min-width: 18px;
  width: 18px;
  height: 18px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Adds a subtle shadow */
}

.unread-messages {
  padding: 0px 4px;
  font-size: 8px;
  margin-top: 2px;
  /* Adjusts inner spacing for better readability */
}

.empty_chat {
  color: aliceblue;
}

input {
  border: 1px solid #ddd !important;
}

#mycontainer main header {
  padding: 9px 21px 4px 30px;
}

#mycontainer main header h2 {
  font-size: 16px;
  line-height: 52px;
}

#mycontainer aside ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
  height: 340px;
  margin-right: 10px;
}

#mycontainer main footer {
  height: 200px;
  height: auto;
  padding: 9px 46px 8px 50px;
}

#mycontainer aside ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
  margin-right: 5px;
  background: #eff3f7;

  /* height: 690px; */
}

#mycontainer aside {
  background: #eff3f7;
  color: black;
  box-shadow: rgb(176 171 193 / 40%) 0px 5px 30px 0px;
}



#mycontainer aside ul::-webkit-scrollbar-track {
  border: none;
  padding-right: 10px;
  margin-right: 10px;
  padding: 2px 0;
  border-radius: 10px;
  background: none;
}

#mycontainer aside ul::-webkit-scrollbar {
  /* border: none; */
  width: 10px;
  margin-right: 10px;
  background: none;
}

#mycontainer aside ul::-webkit-scrollbar-thumb {
  border: none;
  border-radius: 30px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #c1c1c1;
  margin-right: 10px;
  padding-right: 10px;
  /* border: 1px solid #000; */
}

#mycontainer aside li {
  font-size: 13px;
}

#mycontainer aside input::placeholder {
  color: black;
}

#mycontainer aside input {
  color: black;
}

#mycontainer aside li a {
  font-size: 13px;
  width: 100%;
  color: #321691;
  padding-top: 5px;
  padding-bottom: 5px;

}

#mycontainer aside li a:hover {
  font-size: 13px;
  background-color: #5e616a;
  width: 100%;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
}

#mycontainer main header div {
  margin-left: 10px;
  margin-top: 10px;
  /* margin-right: 145px; */
}

#mycontainer main header h2 {
  font-size: 16px;
  line-height: 28px;
}
</style>
