<template>
  <loader v-if="this.LoadingTimingFlag == false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2"
    bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>

  <!-- Product widget section begin -->
  <DashboardHeader></DashboardHeader>
  <div class="text-center">
  </div>
  <section class="product-category-2 profile-section community-section inner-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content tabStyling" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-gird" role="tabpanel" aria-labelledby="pills-gird-tab">
              <div class="row">
                <div class="col-md-4 col-sm-4">
                  <h4 class="p-3">Gallery</h4>
                </div>
                <div class="col-md-12 col-sm-12 right flex">
                  <div class="top-filter">
                    <div class="left right">
                      <a style="margin-top: 20px; margin-left: 15px;" href="#" data-toggle="modal"
                        data-target="#accesscontrol">
                        <i class="fa fa-upload"></i>Manage Access
                      </a>
                      <a style="margin-top: 20px; margin-left: 15px;" href="#" data-toggle="modal"
                        data-target="#usernotification" @click="UploadPictures_Gallary">
                        <i class="fa fa-upload"></i>Upload Pictures
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="padding-top: 30px">
                <div v-for="(gallery_image, index) in (gallery_images)" :key="gallery_image.image"
                  class="col-lg-3 col-md-6">
                  <div class="single-product">
                    <div class="img">
                      <img onclick :src="gallery_image" alt="" style=" object-fit: cover; object-position: top;" />

                      <ul class="more-links">
                        <!-- <li>
                          <a data-toggle="modal" data-target="#usernotification">
                            <i class="fas fa-pencil-alt"></i>
                          </a>
                        </li> -->
                        <li>
                          <a href="javascript:void(0)" @click="deleteImage(index)">
                            <i class="fas fa-trash"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Upload Image Model -->

  <div class="modal fade" id="usernotification" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" v-if="this.modalShow == true">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="close closeModal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </div>
        <form method="post" @submit.prevent="addImage" enctype="multipart/form-data">
          <div class="modal-body">
            <div class="header-area" style="background: #eef3fb;">
              <h4>Upload Gallery Images</h4>
            </div>

            <div class="notification-list">
              <div class="single-list">
                <div class="row" style="width: 100%;">
                  <div class="co-lg-12 col-md-12">
                    <div class="top-filter">
                      <div class="previewImageSetting">
                        <div v-for="(images, key) in preview" class="left col-lg-3 col-md-4 col-sm-6 col-12">
                          <div>
                            <img style="max-width: 130%;" class="img-preview" :src="images" :ref="'image'"
                              v-bind:ref="'image'" />
                            <ul class="more-links">
                              <!-- <li>
                          <a data-toggle="modal" data-target="#usernotification">
                            <i class="fas fa-pencil-alt"></i>
                          </a>
                        </li> -->
                              <li>
                                <a href="javascript:void(0)" @click="delete_Modal_Image(key)">
                                  <i class="fas fa-trash"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>


                      </div>

                      <div class="col-md-12" style="display: flex ;justify-content: center;">
                        <div class="my-input-box">
                          <input type="file" ref="file" @change="previewImage" style="display: none" multiple>
                          <label @click="$refs.file.click()">
                            <i class="custom-button fa fa-upload mt-3"> Upload
                              Pictures</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="view-all-link" data-dismiss="modal" v-if="this.IspictureUploaded == false">cancel</button>
            <button type="submit" class="view-all-link" v-if="this.IspictureUploaded == true">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Access Control Model -->
  <div class="modal fade" id="accesscontrol" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel2"
    aria-hidden="true" v-if="this.modalShow == true">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="close closeModal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </div>

        <div class="modal-body">
          <loader v-if="this.LoadingTimingFlag == false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5"
            speed="2" bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
          <div class="header-area">
            <h4>Manage Gallery Access</h4>
          </div>
          <div class="notification-list">
            <div class="single-list">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <form method="post" @submit.prevent="manageAccess" enctype="multipart/form-data">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="Access">Access Level</label>
                        <select name="acess_level" id="access_level" v-model="access_level">
                          <option value="" selected disabled>Select One</option>
                          <option value="1">Private</option>
                          <option value="3">Public</option>
                          <option value="2">To Members</option>
                        </select>
                      </div>
                    </div>
                    <div class="button-wrapper">
                      <button @click="udpated" class="custom-button">Update</button>
                    </div>
                    <p style="margin-top: 10px;" v-if="access_message" class="alert alert-success">Access Level is
                      updated successfully!</p>
                  </form>
                </div>
                <div class="col-sm-12 col-md-12">
                  <section class="profile-section">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <div class="profile-aside-area">
                          <div class="chat-request">
                            <div class="c-r-heading">
                              <h6 class="title">Gallery Access Requests </h6>
                            </div>
                            <div class="c-r-content">
                              <div v-if="check == true">
                                <div class="c-r-content-list" v-if="access_requests != ''">
                                  <div v-for="(req, i) in access_requests" :key="req" class="single-c-r">
                                    <div style="width: 100%; display: flex">
                                      <div class="left col-sm-2 col-md-2">
                                        <img :src="p_images[i]" alt="" style="width: 50px" />
                                        <div class="active-online"></div>
                                      </div>
                                      <div class="right col-sm-6 col-md-3"
                                        style="text-align: left !important; padding-top: 10px;">
                                        <h4 class="title">
                                          <router-link :to="{
                                            path: '/profile',
                                            query: {
                                              member_id: `${req.member_id}`,
                                            },
                                          }">
                                            <a class="name">
                                              {{ req.first_name }} {{ req.last_name }}
                                              <span class="isvarify">
                                                <!-- <i class="fas fa-check-circle"></i> -->
                                              </span>
                                            </a>
                                          </router-link>
                                        </h4>

                                      </div>
                                      <div class="col-sm-2 col-md-7 model-icons">
                                        <a href="javascript:void(0)" v-if="req.status != '1'" @click.prevent="
                                          updateAccessRequest(
                                            (member = req.member_id),
                                            (status = '1')
                                          )
                                        "><i class="fas fa-check">Grant Access</i>
                                        </a>

                                        <a href="javascript:void(0)" @click.prevent="
                                          updateAccessRequest(
                                            (member = req.member_id),
                                            (status = '0')
                                          )
                                        "><i class="fas fa-trash"> Reject Request</i></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="c-r-content-list error">
                                <p v-if="request_message">{{ request_message }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Ref } from 'vue';
import DashboardHeader from '@/components/DashboardHeader.vue';
export default {
  name: "ManageGallery",
  data() {
    return {
      title: "",
      LoadingTimingFlag: false,
      description: "",
      image: [],
      images_Preview: [],
      preview: [],
      gallery_images: [],
      access_level: "",
      access_message: "",
      opt: [],
      access_requests: "",
      request_message: "",
      p_images: [],
      i: 0,
      imageId: [],
      IspictureUploaded: false,
      modalShow: true,
      count: 0,
      check: true,
    };
  },
  methods: {
    async UploadPictures_Gallary() {
      this.IspictureUploaded = false;
      this.preview.splice(0);
      this.image.splice(0);
    },
    previewImage: function (event) {

      var selectedFiles = event.target.files;
      for (var i = 0; i < selectedFiles.length; i++) {
        this.image.push(selectedFiles[i]);
        this.IspictureUploaded = true;

      }
      this.preview.splice(0);
      for (var i = 0; i < this.image.length; i++) {
        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener('load', function () {

          this.preview.push(reader.result);
        }.bind(this), false);  //add event listener
        reader.readAsDataURL(this.image[i]);
      }
    },
    async delete_Modal_Image(image_index) {

      this.image.splice(image_index, 1);
      this.preview.splice(image_index, 1);


    },
    async deleteImage(image_id) {
      var bodyFormData = new FormData();
      bodyFormData.append("image_id", this.imageId[image_id]),
        await this.axios
          .post("delete_gallary_image", bodyFormData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }).then((result) => {
            // console.log(result, "DeleteId");
            this.gallery_images.splice(image_id, 1);
            this.getImages();
          })
          .catch((err) => { })
    },


    async addImage() {
      this.LoadingTimingFlag = false;
      this.modalShow = false
      var formbody = new FormData();
      formbody.append("subject", this.title),
        formbody.append("description", this.description),
        formbody.append("visibility", this.visibility)
      for (var i = 0; i < this.image.length; i++) {
        this.count++;
        formbody.append('image[' + i + ']', this.image[i]);
        await this.axios
          .post("add_gallery_image", formbody, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            console.log(this.count, "AddImage");
            this.modalShow = false
            console.log(this.image.length, "image")
            console.log(this.count, "count")
            if (this.image.length > 1) {
              if (this.image.length == this.count) {
                $("div").removeClass("modal-backdrop");
                $(".modal-open").css({ paddingRight: "0px" });
                $('body').removeClass('modal-open');
                 if(i == this.image.length-1){
                  this.getImages();
                  }
              }
            } else {
              $("div").removeClass("modal-backdrop");
              $(".modal-open").css({ paddingRight: "0px" });
              $('body').removeClass('modal-open');
               if(i == this.image.length-1){
              this.getImages();
              }
            }
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            this.LoadingTimingFlag = true;
          })
          .finally(() => {
            this.modalShow = true
          });
      }

    },
    async getImages() {
      await this.axios
        .get("get_gallery_image", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          // console.log(result.data.data.gallery.length, "lenght")
          this.gallery_images = result.data.data.gallery;
          this.imageId = result.data.data.image_ids
          console.log(this.imageId);
          this.LoadingTimingFlag = true;
          // console.log(this.imageId);

          // console.log(result, "gallery_images");
          //console.log(result.data.data.gallery);
        })
        .catch((err) => {
          this.LoadingTimingFlag = true;
        });
    },
    async getAccessRequests() {
      await this.axios
        .get("get_access_requests", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          if (result.data.data.requests != "") {
            this.access_requests = result.data.data.requests;
            console.log(this.access_requests, 'req');
            for (let index = 0; index < result.data.data.images.length; index++) {
              this.p_images.push(result.data.data.images[index]);
            }
          }
          else {
            this.check = false;
            this.request_message = "No Requests Received Yet!";
          }
        })
        .catch((err) => { });
    },
    async manageAccess() {
      var formbody = new FormData();
      formbody.append("status", this.access_level),
        await this.axios
          .post("manage_gallery_access", formbody, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            console.log(result.data.data.message, "updateAccessRequest");
            this.modalShow = false
            $("div").removeClass("modal-backdrop");
            $(".modal-open").css({ paddingRight: "0px" });
            $('body').removeClass('modal-open');
            this.access_message = result.data.data.message;
          })
          .catch((err) => { })
          .finally(() => {
            this.modalShow = true
          });
    },
    async getAccessLevel() {
      await this.axios
        .get("profile", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          // console.log(result);
          this.access_level = result.data.data.member_profile[0].gallery_access;
          // console.log(this.access_level);
        })
        .catch((err) => { });
    },
    async updateAccessRequest(member_id, status) {
      this.LoadingTimingFlag = false;
      var formBody = new FormData();
      formBody.append("member_id", member_id), formBody.append("status", status);
      await this.axios
        .post("update_access_request", formBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.getAccessRequests();
          this.LoadingTimingFlag = true;
          console.log(result);

        })
        .catch((err) => { });
    },
  },
  mounted() {
    this.getImages();
    this.getAccessRequests();
    this.getAccessLevel();
  },
  components: { DashboardHeader }
};
</script>

<style scoped>
.img-preview[data-v-0efbdf28] {
  width: 110%;
  height: 180px;
  object-fit: cover;
  object-position: top;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}

.previewImageSetting {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.example {
  background: #ffffff;
  margin: 20px;
  border-color: #e7e7e7;
  padding: 40px;
}

.example pre {
  background: #f9f9f9;
  padding: 18px 6px;
  overflow-x: scroll;
}

.closeModal {
  font-size: 27px;
  margin: 19px;
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 31px;
}

.output {
  font-family: Courier, Courier New, Lucida Console, Monaco, Consolas;
  background: #000000;
  color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  background: #35495e;
  margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
  color: #ffffff;
  border-radius: 50%;
}

.user-image {
  margin: 0 6px 0 0;
  border-radius: 50%;
  height: 22px;
}

.character-option-icon {
  margin: 0 6px 0 0;
  height: 22px;
}

.character-label-icon {
  margin: 0 6px 0 0;
  height: 26px;
}

#usernotification #accesscontrol .modal-body .notification-list .single-list:hover {
  background: none;
}

#usernotification #accesscontrol.modal-body .notification-list .single-list {
  padding: 20px 30px 20px;
}

@media screen and (min-width: 800px) {
  .modal-dialog {
    width: 50%;
  }
}

.modal-body .col-md-12 {
  padding: 10px;
}

.my-input-box {
  display: flex;
}

label {
  padding: 10px;
}

input[type="file"] {
  border: none !important;
}

/* .img-preview {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
} */

.single-product img {
  height: 200px;
}

#multi-select {
  width: 100%;
}

input:focus {
  border: none !important;
}

.single-c-r {
  min-height: 80px;
}

.single-c-r img {
  width: 80px;
  border-radius: 10px;
  height: 60px;
}

.profile-section {
  padding: 0px 0px 20px;
}

.profile-aside-area .chat-request {
  margin-top: 0px;
}

.model-icons a {
  padding: 5px 10px 5px 10px;
  line-height: 30px;
  border-radius: 10px;
  background: #f7e9f8;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  font-size: 15px;
}

.model-icons i {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: -o-linear-gradient(284deg, #f22876 0%, #942dd9 100%);
  background-image: linear-gradient(166deg, #f22876 0%, #942dd9 100%);
}
</style>
