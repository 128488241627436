<template>

    <div class="single-community-menu">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 menu">
                    <ul class="top-menu">
                        <li>
                            <router-link to="dashboard" :class="{ active: $route.name=='Dashboard' }">Dashboard </router-link>
                        </li>
                        <li>
                            <router-link to="/updateprofile" :class="{ active: $route.name=='Update Profile' }">Edit Profile </router-link>
                        </li>
                        <li>
                            <router-link to="/gallery" :class="{ active: $route.name=='Gallery' }"> Gallery</router-link>
                        </li>
                        <li>
                            <router-link to="/saved-search" :class="{ active: $route.name=='Saved Searches' }">Saved Search  </router-link>
                        </li>
                        <li>
                            <router-link to="/match" :class="{ active: $route.name=='Match Criteria' }"> Your Match</router-link>
                        </li>
                        <li>
                            <router-link v-if="this.route.length > 0 " :to= "this.route" :class="{ active: $route.name=='Chat' }"> Messages
                                <!-- <div class="num">47</div> -->
                                <span v-if="unreadMessages" class="unread-dot"></span>
                            </router-link>
                            <router-link v-else to= "/chat" :class="{ active: $route.name=='Chat' }"> Messages
                                <!-- <div class="num">47</div> -->
                                  <!-- Conditional rendering for the unread dot -->
                            <span v-if="unreadMessages" class="unread-dot"></span>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/favourite-profiles" :class="{ active: $route.name=='Favouurite Profiles' }">Favourite Profiles</router-link>
                        </li>
                        <li>
                            <router-link to="/match-profiles" :class="{ active: $route.name=='Match Profiles' }">Match Profiles</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TopMenu",

  data() {
    return {
      chats: "",
      route: "",
      unreadMessages: 0, // Initially, no unread messages
    };
  },

  methods: {
    // Fetch unread messages count
    fetchUnreadMessages() {
      axios
        .get("/unread-messages", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          this.unreadMessages = response.data.count; // Update unread messages count
        })
        .catch((error) => {
          console.error("Error fetching unread messages:", error);
        });
    },

    // Fetch chat data
    fetchChats() {
      axios
        .get("/chats", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          this.chats = res.data.data.chats;
          this.route = "/chat?member_id=" + this.chats[0].members.id;
        })
        .catch((error) => {
          console.error("Error fetching chats:", error);
        });
    },
  },

  mounted() {
    this.fetchChats();
    this.fetchUnreadMessages(); // Fetch unread messages when the component mounts
  },
};
</script>


<style scoped>
.single-community-menu .top-menu{
    text-align: left;
}
.unread-dot {
  display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #d33a3ade;
    border-radius: 50%;
    margin-left: -3px;
    vertical-align: middle;
    margin-bottom: 12px;
}
</style>
