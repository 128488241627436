<template>
    <section class="membership-section">
        <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="content">
                    <div class="section-header">
                        <h6 class="sub-title extra-padding">
                            Upgrade Your Profile
                        </h6>
                        <h2 class="title">
                            Premium Memeber Plan
                        </h2>
                        <p class="text">
                            Benefit from Peyamba at its maximum you!
                        </p>
                    </div>
                </div>
            </div>
        </div>
            <div class="row m-s-top">
            <div class="col-lg-3 col-md-6">
                <div class="plan-info">
                    <div class="icon">
                        <img src="assets/images/membership/icon1.png" alt="">
                    </div>
                    <h4 class="title">
                        Unlimited Messages
                    </h4>
                    <p class="text">
                        Send and receive messages
                        with no limits
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="plan-info">
                    <div class="icon">
                        <img src="assets/images/membership/icon2.png" alt="">
                    </div>
                    <h4 class="title">
                        VIP Badge
                    </h4>
                    <p class="text">
                        Send and receive messages
                        with no limits
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="plan-info">
                    <div class="icon">
                        <img src="assets/images/membership/icon3.png" alt="">
                    </div>
                    <h4 class="title">
                        Unlimited matches
                    </h4>
                    <p class="text">
                        Send and receive messages
                        with no limits
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="plan-info">
                    <div class="icon">
                        <img src="assets/images/membership/icon4.png" alt="">
                    </div>
                    <h4 class="title">
                        Take more messages
                    </h4>
                    <p class="text">
                        Send and receive messages
                        with no limits
                    </p>
                </div>
            </div>
            </div>
            <div class="row pricing_plan_wrapper_for_image">
                <div class="col-lg-12">
                <div class="pricing-plan-wrapper">
                    <img class="left-img" src="assets/images/membership/left-img.png" alt="">
                    <img class="right-img" src="assets/images/membership/right-img.png" alt="">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-4" v-for="plan in plans" :key="plan">
                            <div class="single-plan">
                                <p class="duration">
                                    {{plan.name}}
                                </p>
                                <h4 class="number">
                                    <sup>$</sup>{{ (plan.amount/100).toFixed(2) }}
                                </h4>
                                <!-- <p class="stamet">
                                    Per Month
                                </p> -->
                                <router-link to="/login" class="custom-button">Buy Now!</router-link>
                                <img class="shape" src="assets/images/membership/plan-bg.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="pricing-plans">
        <img class="shape1" src="assets/images/join/heartshape.png" alt="">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MembershipView',
    data() {
        return {
            plans: '',
        }
    },

    methods: {
        async getBillingPlans() {
            await this.axios
            .get("get_billing_plans", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((result) => {
                this.plans = result.data.data.plans.data;
            })
            .catch((err) => {});
        },
    },

    mounted() {
        this.getBillingPlans();
    },
}
</script>
